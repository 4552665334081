<template>
	<div class="content">
		<div class="title-actions">
			<h5 class="title">{{ team.name }}</h5>
			<div class="btns">
				<b-button @click="$router.go(-1)" class="btn btn-info ml-3 px-3">
					<i class="fa fa-arrow-left"></i>
				</b-button>
				<select
					name="tournament"
					id="tournament"
					class="form-control ml-3"
					v-model="currentTournament"
					@change="changeTournament()"
				>
					<option value="">{{ $t('tournament') }}</option>
					<option
						v-for="tournament in tournaments"
						:key="tournament.id"
						:value="tournament.id"
						>{{ tournament.name }}</option
					>
				</select>
			</div>
		</div>
		<div class="card mt-3">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackManagment',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('management') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackTshirts',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('tshirts') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackNotifications',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $tc('notification', 2) | toCapitalize }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackInvitations',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $tc('invitation', 2) | toCapitalize }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackPlayers',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('players') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackTrainingShow',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('trainingShow') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackFormation',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('teamFormation') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackMyFormation',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('myFormation') }}</router-link
						>
					</li>
					<li class="nav-item">
						<router-link
							class="nav-link"
							active-class="active"
							:to="{
								name: 'TeamBackMyInvitePlayers',
								params: { lang: lang, console: console, teamId: teamId },
								query: { tournament: currentTournament },
							}"
							>{{ $t('invitePlayers') }}</router-link
						>
					</li>
				</ul>
			</div>
			<div class="card-body">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				teamId: this.$route.params.teamId,
				columns: [
					{ key: 'order', label: '#', sortable: false },
					{ key: 'name', label: this.$t('name'), sortable: true },
					{ key: 'dts', label: 'DT/CODT', sortable: true },
					{ key: 'actions', label: '', sortable: false },
				],
				tournaments: [],
				currentTournament: '',
			}
		},
		computed: {
			...mapGetters(['lang', 'console', 'team']),
		},
		mounted() {
			this.fetchData()
		},
		watch: {
			$route() {
				if (this.$route.name === 'TeamBack') {
					this.fetchData()
				}
			},
		},
		methods: {
			changeTournament() {
				this.changePath('TeamBack')
			},
			fetchData() {
				const tournament = this.$route.query.tournament
				const params = {
					tournament,
				}
				const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}`
				this.$axios.get(path, { params }).then((response) => {
					const data = response.data
					this.$store.dispatch('SET_TEAM', data.team)
					this.tournaments = data.tournaments
					if (tournament) {
						this.currentTournament = tournament
					} else {
						this.currentTournament = data.tournament_id
					}
				})
			},
			changePath(path) {
				if (this.currentTournament) {
					this.$router.push({
						name: path,
						params: {
							lang: this.lang,
							console: this.console,
							teamId: this.team.id,
						},
						query: { tournament: this.currentTournament },
					})
				}
			},
		},
	}
</script>
