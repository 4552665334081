var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title-actions"},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.team.name))]),_c('div',{staticClass:"btns"},[_c('b-button',{staticClass:"btn btn-info ml-3 px-3",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-arrow-left"})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentTournament),expression:"currentTournament"}],staticClass:"form-control ml-3",attrs:{"name":"tournament","id":"tournament"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentTournament=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.changeTournament()}]}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('tournament')))]),_vm._l((_vm.tournaments),function(tournament){return _c('option',{key:tournament.id,domProps:{"value":tournament.id}},[_vm._v(_vm._s(tournament.name))])})],2)],1)]),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-header"},[_c('ul',{staticClass:"nav nav-tabs card-header-tabs"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackManagment',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('management')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackTshirts',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('tshirts')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackNotifications',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('notification', 2))))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackInvitations',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.$tc('invitation', 2))))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackPlayers',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('players')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackTrainingShow',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('trainingShow')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackFormation',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('teamFormation')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackMyFormation',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('myFormation')))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
							name: 'TeamBackMyInvitePlayers',
							params: { lang: _vm.lang, console: _vm.console, teamId: _vm.teamId },
							query: { tournament: _vm.currentTournament },
						}}},[_vm._v(_vm._s(_vm.$t('invitePlayers')))])],1)])]),_c('div',{staticClass:"card-body"},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }